<template>
    <div class="page-container">
        <div class="page-top">
            <div class="pic-box">
                <img src="../../assets/productdetail/tmp-cover-pic.png" alt="" class="nft-pic">
            </div>
            <div class="info-box">
                <div class="info-title-box">
                    <div class="info-title">唐山女孩——公益捐款</div>
                    <div class="btn-grp">
                        <div class="btn-fav">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav">
                            112
                        </div>
                        <img src="../../assets/productdetail/icon-share.png" alt="" class="icon-share">
                    </div>
                </div>
                <div class="fxl-buycount">
                    <img src="../../assets/productdetail/icon-fxl.png" alt="" class="icon-fxl">
                    <div class="fxl-count">300</div>
                    <div class="buy-count">已有 <span>56</span>人购买</div>
                </div>
                <div class="auth-info">
                    艺术家：王喜文 | 作品集：社会意义数字画作
                </div>
                <div class="price">¥ 20</div>
                <div class="btn-buy">立即购买</div>
                <div class="chain-info">
                    <div class="chain-head">链上信息</div>
                    <div class="chain-block">
                        <div class="chain-info-item">合约地址：0x84FC3ea417Eb7cB229C6ce95843f68dAC59C0088</div>
                        <div class="chain-info-item">合约类型：ERC-1155</div>
                        <div class="chain-info-item">区块链：文昌链</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-bottom">
            <!-- 作者信息 -->
            <div class="auth-block">
                <div class="pic-name">
                    <img src="../../assets/productdetail/icon-author.png" alt="" class="auth-pic">
                    <div class="auth-name">王喜文</div>
                </div>
                <div class="auth-detail">王喜文，数字艺术家，数字经济专家，工学博士，情报学博士后。自幼学习国画，所画风格多样。2009~2018 年
在国家工信部直属单位工作，历任电子商务研究所所长、工业 4.0 研究所所长。现为九三学社中央科技委委员、九
三学社中央促进技术创新工作委员会委员。出版专著有《区块链+5G》、《智能+》等系列丛书共计 50 多本。</div>
            </div>
            <div class="page-title">
                <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-left">
                <div>藏品描述</div>
                <img src="../../assets/icon-artist-arrow.png" alt="" class="arrow-right">
            </div>
            <div class="desc-list">
                <div class="desc-item">
                    <div class="desc-item-head">藏品背景</div>
                    <div class="desc-item-content">“唐山女孩”事件发生以来,已引起社会各界关注,敲响一声警钟的同时,也值得被所有人铭记。“不严惩不足以平民愤,不严惩不足以树法威”王喜文特创作水彩画风格
数字艺术作品“唐山女孩”，永久存证，警醒世人!</div>
                </div>
                <div class="desc-item">
                    <div class="desc-item-head">藏品介绍</div>
                    <div class="desc-item-content">这幅水彩画风格数字艺术作品旨在呼吁保护妇女、消除暴力,传达我们渴望有一个安心、安全的社会环境的心愿。</div>
                </div>
                <div class="desc-item">
                    <div class="desc-item-head blue">权益赋能</div>
                    <div class="desc-item-content blue">藏品全部销售额都将捐赠给江苏省妇女儿童福利基金会——女性伤害援(救）助项目，捐款证书将会以空投形式发放，作为此公益活动凭证。</div>
                </div>
                <div class="desc-item">
                    <div class="desc-item-head">权益说明</div>
                    <div class="desc-item-content">数字藏品可以通俗理解为区块链凭证。数字藏品为虚拟数字商品，特性为不可分割、不可替代、独一无二。数字藏品常见于文化艺术品领域、知识产权的链上发行、流
转、确权等作用，能有效保护链上知识产权，防止篡改、造假等，是区块链技术的一类场景应用。</div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" src="./index.scss" scoped>

</style>